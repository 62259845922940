import {createStore,applyMiddleware,combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension';

import AuthReducer from './reducers/AuthReducer';
import {ContactReducer,FetchContacts} from './reducers/ContactReducer'
import {BlogReducer,FetchBlogs,FetchBlog,UpdateBlog,UpdatebImage} from './reducers/BlogReducer'
import { NewsReducer } from './reducers/NewsReducer';
import { BookReducer } from './reducers/BookReducer';
import { TeamReducer } from './reducers/TeamReducer';

const rootReducers = combineReducers({
    AuthReducer,
    ContactReducer, 
    FetchContacts, 
    BlogReducer,
    FetchBlogs,
    FetchBlog,
    UpdateBlog,
    UpdatebImage,
    NewsReducer,
    BookReducer,
    TeamReducer,
})
const middlewares = [thunkMiddleware];
const Store = createStore(rootReducers,composeWithDevTools(applyMiddleware(...middlewares)))
export default Store