import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    REDIRECT_FALSE,
    SET_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_ERRORS,
    SET_TEAMS,
    SET_TEAM,
    TEAM_REQUEST, 
    TEAM_RESET,
    SET_UPDATE_ERRORS,
    RESET_UPDATE_ERRORS,
    UPDATE_IMAGE_ERRORS,
    RESET_UPDATE_IMAGE_ERRORS
}from '../types/BlogTypes'
const initState = {
    loading: false,
    createErrors:[],
    redirect:false,
    message:'',
    teams:[],
    editErrors: [],
    team:{},
    teamStatus:false,
    updateImageErrors:[]
}


export const TeamReducer = (state=initState,action)=>{
    const {type,payload} =action;
    if(type === SET_LOADER){
        return{...state,loading:true}
    }
   else if(type === CLOSE_LOADER){
        return{...state,loading:false}
    }
    else if(action.type === CREATE_ERRORS){
        return{
            ...state,
            createErrors:action.payload.errors
        }
    }else if(type === REDIRECT_TRUE){
        return{...state,redirect:true}
    }
    else if(type === REDIRECT_FALSE){
        return{...state,redirect:false}
    }
    else if(type === SET_MESSAGE){
        return {...state,message:action.payload}
    }
    else if(type === REMOVE_MESSAGE){
        return {...state,message:''}
    }
    else if(type===REMOVE_ERRORS){
        return {...state,createErrors:[]}
    }
    if (type === SET_TEAMS) {
        return {
            ...state,
            teams: payload.response
        }
    }

    if (type === SET_TEAM) {
        return { ...state, team: payload };
    } else if (type === TEAM_REQUEST) {
        return { ...state, teamStatus: true };
    } else if (type === TEAM_RESET) {
        return { ...state, teamStatus: false }
    }

    // update for all
    if (type === SET_UPDATE_ERRORS) {
        return { ...state, editErrors: payload };
    } else if (type === RESET_UPDATE_ERRORS) {
        return {
            ...state, editErrors: [],
        }
    }

    // image update

    if (type === UPDATE_IMAGE_ERRORS) {
		return {
			...state,
			updateImageErrors: payload,
		};
	} else if (type === RESET_UPDATE_IMAGE_ERRORS) {
		return {
			...state,
			updateImageErrors: [],
		};
	}

    else{
        return state;
    }
}

