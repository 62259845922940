import React from 'react';
import './footer.css'
import location from '../../../img/location.png'
import {Link} from 'react-router-dom'
const Footer = () => {
    let date = new Date();
    let currentYear = date.getFullYear()
    return <footer className="site-footer p-1">
        <div className="container pt-5 pb-5">
            <div id="footer-widgets">
                <div className="row">
                    <div className="col-md-3 col-sm-6 widget-container">
                        <div id="text-2" className="widget widget_text">
                            <h3 className="footer-widget-title"><span>About Airawati</span></h3>
                            <span className="underline left" />
                            <div className="textwidget">
                                We provide quality books and novels containing dymanic knowledge as per public demand for different age groups.
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 widget-container">
                        <div id="nav_menu-2" className="widget widget_nav_menu">
                            <h3 className="footer-widget-title"><span>Quick Links</span></h3>
                            <span className="underline left" />
                            <div className="menu-quick-links-container">
                                <ul id="menu-quick-links" className="menu">
                                    <li><Link style={{textDecoration:'none'}} to=''>Home</Link></li>
                                    <li><Link style={{textDecoration:'none'}} to='/about-us'>About Us</Link></li>
                                    <li><Link style={{textDecoration:'none'}} to='/books'>Our Products</Link></li>
                                    <li><Link style={{textDecoration:'none'}} to='/testimonials'>Testimonials</Link></li>
                                    <li><Link style={{textDecoration:'none'}} to='/contact'>Contact Us</Link></li>
                                </ul></div>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-6 widget-container">
                        <div id="nav_menu-2" className="widget widget_nav_menu">
                            <h3 className="footer-widget-title"><span>Contact Us</span></h3>
                            <span className="underline left" />
                            <address>
                                <div className="info">
                                    <i className="fa fa-location-arrow" />
                                    <span>Dillibazar,Kathmandu</span>
                                </div>
                                <div className="info">
                                    <i className="fa fa-envelope" />
                                    <span>airawatiprakashan@gmail.com</span>
                                </div>
                                <div className="info">
                                    <i className="fa fa-phone" />
                                    <span>+ 01-4421555</span>
                                </div>
                            </address>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-4 widget-container">
                        <div className="widget twitter-widget">
                            <h3 className="footer-widget-title"><span>Our Loaction</span></h3>
                            <span className="underline left" />
                            <a href="https://www.google.com/maps/place/Airawati+Prakashan+pvt+ltd/@27.7066247,85.3287942,15z/data=!4m5!3m4!1s0x0:0x443b99ef512916cf!8m2!3d27.7066247!4d85.3287942"
                             target='_blank' rel="noreferrer">
                                <img src={location} className='w-100 ps-3 pe-3' alt="location of airawati publication" /></a>
                        </div>
                    </div>
                    <div className="clearfix hidden-lg hidden-md hidden-xs tablet-margin-bottom" />
                </div>
            </div>
        </div>
        <hr style={{width:'90%',margin:'auto',color:'#fff'}} />
        <div className="powered pt-3">
            <p>&copy;{currentYear} Airawati Publication</p>
            <p>Powered by smart innovation</p>
        </div>
    </footer>
};

export default Footer;
