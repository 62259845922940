export const CREATE_ERRORS = 'CREATE_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_LOADER = 'SET_LOADER';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const REDIRECT_TRUE = 'REDIRECT_TURE';
export const REDIRECT_FALSE = 'REDIRECT_FALSE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_CATEGOR = 'SET_CATEGOR';
export const CATEGORY_REQUEST = 'CATEGORY_REQUEST';
export const CATEGORY_RESET = 'CATEGORY_RESET';
export const  SET_UPDATE_ERRORS = ' SET_UPDATE_ERRORS';
export const  RESET_UPDATE_ERRORS = ' RESET_UPDATE_ERRORS';
export const  UPDATE_IMAGE_ERRORS = ' UPDATE_IMAGE_ERRORS';
export const  RESET_UPDATE_IMAGE_ERRORS = ' RESET_UPDATE_IMAGE_ERRORS';
export const  SET_AUTHORS = 'SET_AUTHORS';
export const  SET_AUTHOR = 'SET_AUTHOR';
export const  AUTHOR_REQUEST = 'AUTHOR_REQUEST';
export const  AUTHOR_RESET = 'AUTHOR_RESET';
export const  SET_BOOKS = 'SET_BOOKS';
export const  SET_BOOK = 'SET_BOOK';
export const  BOOK_RESET = 'BOOK_RESET';
export const  BOOK_REQUEST = ' BOOK_REQUEST';
export const  SET_DETAILS = ' SET_DETAILS';
export const  SET_REVIEWS = ' SET_REVIEWS';


