import React from 'react';
import './header.css'
import logo from '../../img/logo-airwati.png'
import {NavLink } from 'react-router-dom';

const Header = () => {
    return <nav className="navbar navbar-expand-lg header">
        <div className="container-fluid headers">
            <NavLink to='/' id='link' className="navbar-brand" activeClassName="navbar__link--active">
                <img src={logo} alt="logo of airawati" height={75} width={75} />
            </NavLink>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <i className="fas fa-bars navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                <div className="navbar-nav">
                    <NavLink
                        exact
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/"
                    >
                        Home
                    </NavLink>
                    <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/about-us"
                    >
                        about us
                    </NavLink>
                    <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/books"
                    >
                        Our Products
                    </NavLink>
                    <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/testimonials"
                    >
                        testimonials
                    </NavLink>
                    <NavLink
                        activeClassName="navbar__link--active"
                        className="navbar__link"
                        to="/contact"
                    >
                        contact us
                    </NavLink>
                    <div className="msocial-icon d-flex">
                        <li><a href="https://www.facebook.com/airawatiprakashan/" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f" /></a></li>
                        <li><i className="fab fa-twitter" /></li>
                        <li><i className="fab fa-linkedin-in" /></li>
                    </div>
                    <div className="social-icon">
                        <li><a href="https://www.facebook.com/airawatiprakashan/" target='_blank' rel="noreferrer"><i className="fab fa-facebook-f" /></a></li>
                        <li><i className="fab fa-twitter" /></li>
                        <li><i className="fab fa-linkedin-in" /></li>
                    </div>
                </div>
            </div>
        </div>
    </nav>;
};

export default Header;
