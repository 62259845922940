export const CREATE_ERRORS = 'CREATE_ERRORS';
export const REMOVE_ERRORS = 'REMOVE_ERRORS';
export const SET_LOADER = 'SET_LOADER';
export const CLOSE_LOADER = 'CLOSE_LOADER';
export const REDIRECT_TRUE = 'REDIRECT_TURE';
export const REDIRECT_FALSE = 'REDIRECT_FALSE';
export const SET_MESSAGE = 'SET_MESSAGE';
export const REMOVE_MESSAGE = 'REMOVE_MESSAGE';
export const  SET_BLOGS = ' SET_BLOGS';
export const  SET_BLOG = ' SET_BLOG';
export const  SET_DETAILS = ' SET_DETAILS';
export const  BLOG_RESET = ' BLOG_RESET';
export const  BLOG_REQUEST = ' BLOG_REQUEST';
export const  SET_UPDATE_ERRORS = ' SET_UPDATE_ERRORS';
export const  RESET_UPDATE_ERRORS = ' RESET_UPDATE_ERRORS';
export const  UPDATE_IMAGE_ERRORS = ' UPDATE_IMAGE_ERRORS';
export const  RESET_UPDATE_IMAGE_ERRORS = ' RESET_UPDATE_IMAGE_ERRORS';
export const  SET_TEAMS = 'SET_TEAMS';
export const  SET_TEAM = 'SET_TEAM';
export const  TEAM_REQUEST = 'TEAM_REQUEST';
export const  TEAM_RESET = 'TEAM_RESET';


