import React, { useEffect } from 'react';
import Typewriter from 'typewriter-effect';
import Footer from '../components/nav/footer/Footer';
import Header from '../components/nav/Header';
import { Link } from 'react-router-dom'
import './style.css'


const About = () => {
    return <div className='news'>
        <Header />
        <div className="about-us-section col-md-10 offset-md-1 pb-5 pt-5 ps-3">
            <div className="row w-100 pt-md-3 pt-md-5">
                <div className="col-md-6">
                    <div className="about-us-head pb-4">
                        <h3><span>About -us</span></h3>
                    </div>
                    <div className="about-us-detail p-md-5 mb-4">
                        <p>Airawati Prakashan is based on the 'Education for all' policy of
                    the Government of Nepal.We Incorporate student friendly and
                    contemporary textbooks and novels related to the real life scenario which are inclusive
                    and contextual as possible.We provide interdisciplinary books
                    with approach to address the demands of the 21st century.
                        </p>
                    </div>
                </div>
                <div className="col-md-6 pt-md-5 mt-3">
                    <div className="about-us-img ms-3">
                        <img src="./images/about.jpg" alt="about__img" className='w-100' />
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>;
};

export default About;
