import {
    SET_LOADER,
    CLOSE_LOADER,
    CREATE_ERRORS,
    REDIRECT_TRUE,
    REDIRECT_FALSE,
    SET_MESSAGE,
    REMOVE_MESSAGE,
    REMOVE_ERRORS,
    SET_CATEGORY,
    SET_CATEGOR,
    CATEGORY_REQUEST,
    CATEGORY_RESET,
    SET_UPDATE_ERRORS,
    RESET_UPDATE_ERRORS,
    SET_AUTHORS,
    SET_AUTHOR,
    AUTHOR_REQUEST,
    AUTHOR_RESET,
    SET_BOOKS,
    SET_BOOK,
    BOOK_REQUEST,
    BOOK_RESET,
    RESET_UPDATE_IMAGE_ERRORS,
    UPDATE_IMAGE_ERRORS,
    SET_DETAILS,
    SET_REVIEWS,
} from '../types/BookTypes';

const initState = {
    loading: false,
    createErrors: [],
    redirect: false,
    message: '',
    categories: [],
    authors: [],
    books:[],
    reviews:[],
    categoryStatus: false,
    authorStatus: false,
    bookStatus: false,
    category: {},
    author:{},
    book:{},
    details:{},
    editErrors: [],
    updateImageErrors:[]
}

export const BookReducer = (state = initState, action) => {
    const { type, payload } = action;
    if (type === SET_LOADER) {
        return { ...state, loading: true }
    }
    else if (type === CLOSE_LOADER) {
        return { ...state, loading: false }
    }
    else if (action.type === CREATE_ERRORS) {
        return {
            ...state,
            createErrors: action.payload.errors
        }
    } else if (type === REDIRECT_TRUE) {
        return { ...state, redirect: true }
    }
    else if (type === REDIRECT_FALSE) {
        return { ...state, redirect: false }
    }
    else if (type === SET_MESSAGE) {
        return { ...state, message: action.payload }
    }
    else if (type === REMOVE_MESSAGE) {
        return { ...state, message: '' }
    }
    else if (type === REMOVE_ERRORS) {
        return { ...state, createErrors: [] }
    }
    // GET all CATEGORY 

    if (type === SET_CATEGORY) {
        return {
            ...state,
            categories: payload.response
        }
    }
    // get single category
    if (type === SET_CATEGOR) {
        return { ...state, category: payload };
    } else if (type === CATEGORY_REQUEST) {
        return { ...state, categoryStatus: true };
    } else if (type === CATEGORY_RESET) {
        return { ...state, categoryStatus: false }
    }

    // update for all
    if (type === SET_UPDATE_ERRORS) {
        return { ...state, editErrors: payload };
    } else if (type === RESET_UPDATE_ERRORS) {
        return {
            ...state, editErrors: [],
        }
    }

    // get all authors

    if (type === SET_AUTHORS) {
        return {
            ...state,
            authors: payload.response
        }
    }
    // get single author
    if (type === SET_AUTHOR) {
        return { ...state, author: payload };
    } else if (type === AUTHOR_REQUEST) {
        return { ...state, authorStatus: true };
    } else if (type === AUTHOR_RESET) {
        return { ...state, authorStatus: false }
    }
    
    // get all books

    if (type === SET_BOOKS) {
        return {
            ...state,
            books: payload.response
        }
    }

    // get single book

    if (type === SET_BOOK) {
        return { ...state, book: payload };
    } else if (type === BOOK_REQUEST) {
        return { ...state, bookStatus: true };
    } else if (type === BOOK_RESET) {
        return { ...state, bookStatus: false }
    }

    // update image

    if (type === UPDATE_IMAGE_ERRORS) {
		return {
			...state,
			updateImageErrors: payload,
		};
	} else if (type === RESET_UPDATE_IMAGE_ERRORS) {
		return {
			...state,
			updateImageErrors: [],
		};
	}

    // book details with slug
    else if(type === SET_DETAILS){
        return{...state, details: payload}
    }

    // get reviews
    if (type === SET_REVIEWS) {
        return {
            ...state,
            reviews: payload.response
        }
    }

    else {
        return state;
    }
}

