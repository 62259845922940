import React, { lazy, Suspense } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import Loading from "./components/nav/loading/Loading";
import NotFound from "./components/nav/notfound/NotFound";
import About from "./pages/About";

const Testimonials = lazy(()=>import("./pages/Testimonials"));

const Allreviews = lazy(()=>import("./components/admin/pages/reviews/Allreviews"));

const Home = lazy(() => import('./Home'))
const Contacts = lazy(() => import('./pages/Contact'))
const Books = lazy(() => import("./pages/Books"));
const News = lazy(() => import("./pages/News"));
const Blogs = lazy(() => import("./pages/Blogs"));
const Login = lazy(() => import("./pages/auth/Login"));
const PrivateRoute = lazy(() => import("./components/routes/PrivateRoute"));
const RouteLinks = lazy(() => import("./components/routes/RouteLinks"));
const Dashboard = lazy(() => import("./components/admin/Dashboard"));
const AllContact = lazy(() => import("./components/admin/pages/contact/AllContact"));
const CreateBlog = lazy(() => import("./components/admin/pages/blog/CreateBlog"));
const AllBlogs = lazy(() => import("./components/admin/pages/blog/AllBlogs"));
const UpdateBlog = lazy(() => import("./components/admin/pages/blog/UpdateBlog"));
const UpdateBlogImage = lazy(() => import("./components/admin/pages/blog/UpdateBlogImage"));
const BlogDetail = lazy(() => import("./pages/innerpage/BlogDetail"));
const CreateNews = lazy(() => import("./components/admin/pages/news/CreateNews"));
const AllNews = lazy(() => import("./components/admin/pages/news/AllNews"));
const UpdateNews = lazy(() => import("./components/admin/pages/news/UpdateNews"));
const UpdateNewsImage = lazy(() => import("./components/admin/pages/news/UpdateNewsImage"));
const NewsDetail = lazy(() => import("./pages/innerpage/NewsDetail"));
const CreateCategory = lazy(() => import("./components/admin/pages/book/category/CreateCategory"));
const AllCategories = lazy(() => import("./components/admin/pages/book/category/AllCategories"));
const UpdateCategory = lazy(() => import("./components/admin/pages/book/category/UpdateCategory"));
const CreateAuthor = lazy(() => import("./components/admin/pages/book/author/CreateAuthor"));
const AllAuthor = lazy(() => import("./components/admin/pages/book/author/AllAuthor"));
const UpdateAuthor = lazy(() => import("./components/admin/pages/book/author/UpdateAuthor"));
const CreateBook = lazy(() => import("./components/admin/pages/book/book/CreateBook"));
const AllBooks = lazy(() => import("./components/admin/pages/book/book/AllBooks"));
const UpdateBook = lazy(() => import("./components/admin/pages/book/book/UpdateBook"));
const UpdateBookImage = lazy(() => import("./components/admin/pages/book/book/UpdateBookImage"));
const BookDetail = lazy(() => import("./pages/innerpage/BookDetail"));
const TeamUpdate = lazy(()=>import("./components/admin/pages/team/TeamUpdate"));
const UpdateTeamImage = lazy(()=>import("./components/admin/pages/team/UpdateTeamimg"));

const Createteam =lazy(()=>import("./components/admin/pages/team/Createteam"))
const AllTeams =lazy(()=>import("./components/admin/pages/team/AllTeams"));

function App() {
  return (
    <Router>
      <Suspense fallback={
        <div style={{ paddingTop: '240px' }}>
          <Loading />
        </div>
      }>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contacts} />
          <Route exact path="/books" component={Books} />
          {/* <Route exact path="/news" component={News} /> */}
          {/* <Route exact path="/blogs" component={Blogs} /> */}
          <Route exact path="/about-us" component={About} />
          <Route exact path="/testimonials" component={Testimonials} />

          {/* <Route exact path="/blog-details/:slug" component={BlogDetail} />
          <Route exact path="/news-details/:slug" component={NewsDetail} /> */}
          <Route exact path="/book-details/:slug" component={BookDetail} />

          <RouteLinks exact path="/admin/login" component={Login} />
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/all-contacts' component={AllContact} />
          <PrivateRoute exact path='/add-blog' component={CreateBlog} />
          <PrivateRoute exact path='/all-blogs' component={AllBlogs} />
          <PrivateRoute exact path='/update-blog/:id' component={UpdateBlog} />
          <PrivateRoute exact path="/updateImage/:id" component={UpdateBlogImage} />

          <PrivateRoute exact path='/add-news' component={CreateNews} />
          <PrivateRoute exact path='/all-news' component={AllNews} />
          <PrivateRoute exact path='/update-news/:id' component={UpdateNews} />
          <PrivateRoute exact path="/updatenImage/:id" component={UpdateNewsImage} />

          <PrivateRoute exact path="/create-category" component={CreateCategory} />
          <PrivateRoute exact path="/all-category" component={AllCategories} />
          <PrivateRoute exact path='/update-category/:id' component={UpdateCategory} />

          <PrivateRoute exact path="/create-author" component={CreateAuthor} />
          <PrivateRoute exact path="/all-author" component={AllAuthor} />
          <PrivateRoute exact path="/update-author/:id" component={UpdateAuthor} />

          <PrivateRoute exact path="/create-book" component={CreateBook} />
          <PrivateRoute exact path="/all-books" component={AllBooks} />
          <PrivateRoute exact path="/update-book/:id" component={UpdateBook} />
          <PrivateRoute exact path="/updatebImage/:id" component={UpdateBookImage} />

          <PrivateRoute exact path="/create-team" component={Createteam} />
          <PrivateRoute exact path="/all-teams" component={AllTeams} />
          <PrivateRoute exact path='/update-team/:id' component={TeamUpdate} />
          <PrivateRoute exact path='/updatetImage/:id' component={UpdateTeamImage} />

          <PrivateRoute exact path="/all-reviews" component={Allreviews} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
